import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Echo from "laravel-echo";
import instance from "@/store/axiosConfig";
import store from "./store/index";
import "./assets/css/app.css";
import "./assets/css/global.css";
import BackToTop from "vue-backtotop";
import Chat from "vue-beautiful-chat";
import vSelect from "vue-select";
import Spinner from "vue-spinkit";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-select/dist/vue-select.css";
import vClickOutside from 'v-click-outside';
const feather = require("feather-icons");

feather.replace();
Vue.use(BackToTop);
Vue.use(Chat);
Vue.use(vSelect);
Vue.use(VueSweetalert2);
Vue.use(vClickOutside);
Vue.component("Spinner", Spinner);
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");

const appTheme = localStorage.getItem("theme");

if (
  appTheme === "dark" &&
  document.querySelector("body").classList.contains("app-theme")
) {
  document.querySelector("body").classList.add("bg-primary-dark");
} else {
  document.querySelector("body").classList.add("bg-secondary-light");
}
window.Pusher = require("pusher-js");

window.Echo = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: true,
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        instance
          .post("broadcasting/auth", {
            socket_id: socketId,
            channel_name: channel.name,
          })
          .then((response) => {
            callback(false, response.data);
          })
          .catch((error) => {
            callback(true, error);
          });
      },
    };
  },
});
if (store.state.auth.loggedIn) {
  store.dispatch("START_LISTENING_PUSHER_CHANNEL");
  store.dispatch("get_all_unread");
}
// console.log(store.getters.userid);
// window.Swal = Vue.config.globalProperties.$swal;
