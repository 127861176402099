<template>
  <div>
    <div
      class="flex h-screen items-center justify-center w-full fixed top-0 right-0 bottom-0 bg-gray-900/50"
    >
      <div
        class="max-w-sm overflow-hidden rounded-xl bg-white shadow-md duration-200 hover:shadow-xl relative"
      >
        <img
          :src="selectedMedia.image.desktop_image"
          alt="plant"
          class="h-auto w-full"
        />
        <button
          @click="closeDialog()"
          type="button"
          class="absolute top-3 right-3 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          data-modal-toggle="defaultModal"
        >
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <div class="p-3">
          <div class="p-2">
            <h5 class="text-sm sm:text-lg font-semibold">
              {{ selectedMedia.title }}
            </h5>
            <p class="mb-2 text-xs sm:text-md text-gradient text-dark">
              {{ selectedMedia.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["selectedMedia"],
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="stylus" scoped></style>
