<template>
  <div>
    <div
      class="flex flex-col sm:justify-between items-center text-center sm:flex-row"
    >
      <BannerSlider />
    </div>
  </div>
</template>
<script>
import feather from "feather-icons";
import BannerSlider from "./bannerSlider";

export default {
  name: "Home",
  components: {
    BannerSlider,
  },
  created() {
    this.theme = localStorage.getItem("theme") || "light";
  },
  mounted() {
    feather.replace();
    this.theme = localStorage.getItem("theme") || "light";
  },
  updated() {
    feather.replace();
  },
  methods: {},
};
</script>
<style scoped></style>
