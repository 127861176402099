<template>
  <div class="flex w-full justify-between ads sm:h-60 h-32">
    <a
      href="#"
      class="flex flex-col mr-2 sm:mr-5 w-1/2 items-center border shadow-md md:flex-row"
    >
      <img
        class="object-cover w-full h-full rounded-lg"
        src="https://homebox.a.bigcontent.io/v1/static/En_Homefest_HP_Desk_UAE"
        alt=""
      />
    </a>
    <a
      href="#"
      class="flex flex-col w-1/2 items-center border shadow-md md:flex-row"
    >
      <img
        class="object-cover w-full h-full rounded-lg"
        src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/real-estate-ad-design-template-4d8887ae7a01950bec450938a5a58618_screen.jpg?ts=1577124034"
        alt=""
      />
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped></style>
