<template>
  <nav id="nav" class="mx-auto justify-between items-center xl:px-16">
    <!-- Header start -->
    <div class="z-10 max-w-screen-lg xl:max-w-screen-xl block md:flex justify-between items-center mx-auto my-2">
      <!-- Header menu links and small screen hamburger menu -->
      <div class="flex justify-between items-center px-4 md:px-0">
        <!-- Header logos -->
        <div>
          <router-link to="/"><img src="@/assets/logo.png" class="logo" alt="Dark Logo" />
          </router-link>
        </div>
        <div class="flex justify-center items-center">
          <!-- Small screen hamburger menu -->
          <div class="flex justify-center items-center md:hidden">
            <dropdown placement="right" v-if="(access && userType == 'council') || userType == 'company'">
              <template v-slot:button>
                <div @click="isOpen = false"
                  class="cursor-pointer font-general-medium block text-left sm:text-sm font-medium txt-mobile hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 border-t-2 pt-0 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:w-6 md:h-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="1">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                  </svg>
                  <div v-if="unread_count"
                    class="unread bg-red-500 text-white w-3 h-3 p-1 flex justify-center items-center rounded-full absolute right-0 bottom-2">
                    <span style="font-size: 10px">{{ unread_count }}</span>
                  </div>
                </div>
              </template>
              <template v-slot:content>
                <div v-if="notifications.length > 0" class="m-1">
                  <div v-for="(notification, index) in notifications" :key="index"
                    class="flex flex-col justify-center cursor-pointer hover:bg-gray-100 py-1 px-3 txt-mobile text-lime-700">
                    <div class="flex">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                      </svg>
                      <h6 class="mb-1 ml-2 font-weight-normal">
                        {{ notification.message }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="m-1" v-if="unread && unread_count && unread_count != 0">
                  <div
                    class="flex flex-col justify-center cursor-pointer hover:bg-gray-100 py-1 px-3 txt-mobile text-blue-800"
                    v-for="(message, index) in unread" :key="index" @click="
                      open_chat();
                      getNoti(
                        message.session_id,
                        message.participants[0].user.name
                      );
                    ">
                    <div v-if="message.messages_count != 0">
                      <div class="flex">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                          stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                        </svg>
                        <div>
                          <h6 class="mb-1 font-weight-normal">
                            <span class="font-bold">{{ message.messages_count }} message</span>
                            from {{ message.participants[0].user.name }}
                          </h6>
                          <p class="mb-0 text-secondary">
                            <i class="fa fa-clock me-1" aria-hidden="true"></i>
                            {{ message.latest_message.time }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex justify-center items-center flex-row h-full"
                  v-if="unread_count == 0 && notifications.length == 0">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 text-gray-500" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="1">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <p class="text-gray-500 ml-1">No Notification.</p>
                </div>
              </template>
            </dropdown>
            <dropdown placement="right" v-if="access" width="w-36">
              <template v-slot:button>
                <img @click="isOpen = false" alt="" :src="userImg"
                  class="rounded-full cursor-pointer w-6 md:w-11 h-6 mx-2 md:mx-0 md:h-11" />
              </template>
              <template v-slot:content>
                <p
                  class="cursor-pointer block text-left sm:text-sm font-bold txt-mobile hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 py-1 px-3 sm:py-2 pt-3 sm:pt-2">
                  {{ name }}
                </p>
                <hr />
                <router-link
                  class="cursor-pointer block text-left sm:text-sm font-bold txt-mobile hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 py-1 px-3 sm:py-2 pt-3 sm:pt-2"
                  v-if="access && userType == 'council'" :to="{
                    path: '/council',
                    query: { name: name, id: councilId },
                  }">
                  Profile
                </router-link>
                <router-link
                  class="cursor-pointer block text-left sm:text-sm font-bold txt-mobile hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 py-1 px-3 sm:py-2 pt-3 sm:pt-2"
                  v-if="access && userType == 'company'" :to="{
                    path: '/profile/',
                    query: { name: name, company_id: companyId },
                  }">
                  Profile
                </router-link>
                <a @click="open_chat()"
                  class="cursor-pointer block text-left sm:text-sm font-bold txt-mobile hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 py-1 px-3 sm:py-2 pt-3 sm:pt-2"
                  role="menuitem" tabindex="-1" id="menu-item-1">Chat</a>
                <a role="button" @click="logout()"
                  class="block text-left sm:text-sm font-bold txt-mobile hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 pt-3 py-1 px-3 sm:pt-2">Logout</a>
              </template>
            </dropdown>
            <button @click="isOpen = !isOpen" type="button" class="focus:outline-none" aria-label="Hamburger Menu">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                class="h-7 w-7 fill-current text-secondary-dark dark:text-ternary-light pl-2">
                <path v-if="isOpen" fill-rule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                  clip-rule="evenodd"></path>
                <path v-if="!isOpen" fill-rule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z">
                </path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!-- Header links -->
      <AppHeaderLinks @closeit="closeMenu2($event)" :isOpen="isOpen" />

      <!-- Header right section buttons -->
      <div class="hidden md:flex justify-between items-center md:flex-row">
        <dropdown placement="right" v-if="access && (userType == 'council' || userType == 'company')">
          <template v-slot:button>
            <div
              class="cursor-pointer relative font-general-medium block text-left sm:text-sm font-medium txt-mobile hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" stroke-width="1">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
              </svg>
              <div v-if="unread_count"
                class="unread bg-red-500 text-white w-3 h-3 p-1 flex justify-center items-center rounded-full absolute right-0 bottom-2">
                <span style="font-size: 10px">{{ unread_count }}</span>
              </div>
            </div>
          </template>
          <template v-slot:content>
            <div v-if="notifications.length > 0" class="m-1">
              <div v-for="(notification, index) in notifications" :key="index"
                class="flex flex-col justify-center cursor-pointer hover:bg-gray-100 py-1 px-3 txt-mobile text-lime-700">
                <div class="flex">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                  </svg>
                  <h6 class="mb-1 ml-1 font-weight-normal">
                    {{ notification.message }}
                  </h6>
                </div>
              </div>
            </div>
            <div class="m-1" v-if="unread && unread_count && unread_count != 0">
              <div @click="
                  open_chat();
                  getNoti(
                    message.session_id,
                    message.participants[0].user.name
                  );
                "
                class="flex flex-col justify-center cursor-pointer hover:bg-gray-100 py-1 px-3 txt-mobile text-blue-800"
                v-for="(message, index) in unread" :key="index">
                <div v-if="message.messages_count != 0">
                  <div class="flex">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                    </svg>
                    <div>
                      <h6 class="mb-1 font-weight-normal">
                        <span class="font-bold">{{ message.messages_count }} message</span>
                        from {{ message.participants[0].user.name }}
                      </h6>
                      <p class="mb-0 text-secondary">
                        <i class="fa fa-clock me-1" aria-hidden="true"></i> {{ message.latest_message.time }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-center items-center flex-row h-full"
              v-if="unread_count == 0 && notifications.length == 0">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 text-gray-500" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" stroke-width="1">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <p class="text-gray-500 ml-1">No Notification.</p>
            </div>
          </template>
        </dropdown>
        <dropdown placement="right" v-if="access">
          <template v-slot:button>
            <img alt="" :src="userImg" class="rounded-full cursor-pointer w-7 md:w-11 h-7 md:h-11" />
          </template>
          <template v-slot:content>
            <p
              class="cursor-pointer block text-left sm:text-sm font-bold txt-mobile hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 pt-3 sm:pt-2">
              {{ name }}
            </p>
            <hr />
            <router-link
              class="cursor-pointer block text-left sm:text-sm font-bold txt-mobile hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 pt-3 sm:pt-2"
              v-if="access && userType == 'council'" :to="{
                path: '/council',
                query: { name: name, id: councilId },
              }">
              Profile
            </router-link>
            <router-link
              class="cursor-pointer block text-left sm:text-sm font-bold txt-mobile hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 pt-3 sm:pt-2"
              v-if="access && userType == 'company'" :to="{
                path: '/profile/' + name,
                query: { company_id: companyId },
              }">
              Profile
            </router-link>
            <a @click="open_chat()"
              class="cursor-pointer block text-left sm:text-sm font-bold txt-mobile hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 pt-3 sm:pt-2"
              role="menuitem" tabindex="-1" id="menu-item-1">Chat</a>
            <a role="button" @click="logout()"
              class="block text-left sm:text-sm font-bold txt-mobile hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 pt-3 sm:pt-2">Logout</a>
          </template>
        </dropdown>
        <!-- Theme switcher large screen -->
        <span v-else @mouseover="showLoginPopUp=true" @mouseleave="showLoginPopUp = false">
          <router-link to="/login" 
          class="font-general-medium block text-left sm:text-sm font-medium txt-mobile hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark">
          Login</router-link>
          <div class="user-avatar__dropdown" v-if="showLoginPopUp" >
            <div class="user-menu--dropdown" data-qs="my-account-menu">
              <div data-qs="user-info">
                <div>
                  <div class="user-menu">
                    <div class="user-menu__signin-area">
                      <div class="user-menu__title-text">Sign in if you have already registered. </div>
                      <div class="button user-menu__button">Sign in</div>
                    </div>
                  </div>
                  <div class="user-menu user-menu-bottom">
                    <div class="user-menu__signin-area">
                      <div class="user-menu__title-text">For register contact us </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
        
      </div>
    </div>
    <Chat id="chat" @close_chat="closeChat($event)" ref="getChat" />
  </nav>
</template>
<script>
  import feather from "feather-icons";
  import AppHeaderLinks from "./AppHeaderLinks.vue";
  import { mapState, mapGetters } from "vuex";
  import Dropdown from "../reusable/Dropdown.vue";
  import Chat from "./ChatModal.vue";
  // import instance from "@/store/axiosConfig";
  export default {
    components: {
      AppHeaderLinks,
      Dropdown,
      Chat,
    },
    data() {
      return {
        isOpen: false,
        theme: "",
        openChat: false,
        showLoginPopUp:false
      };
    },
    computed: {
      currentRouteName() {
        return this.$route.name;
      },
      ...mapState({
        access: (state) => state.auth.accesstoken,
        name: (state) => state.auth.name,
        userImg: (state) => state.auth.user_img,
        councilId: (state) => state.auth.council_id,
        companyId: (state) => state.auth.company_id,
        userType: (state) => state.auth.user_type,
      }),
      ...mapGetters(["unread", "notifications"]),
      unread_count() {
        return this.unread.reduce((sum, current) => {
          return sum + current.messages_count;
        }, 0);
      },
    },
    methods: {
      test(){
        console.log('login');
      },
      getNoti(session_id, to) {
        this.$nextTick(() => {
          this.$refs.getChat.getSesseionMessage(session_id, to);
        });
      },
      closeChat(val) {
        this.openChat = val;
        document.getElementById("chat").classList.add("hidden");
        document
          .getElementsByTagName("html")[0]
          .classList.remove("overflow-y-hidden");
      },
      closeMenu2(value) {
        this.isOpen = value;
      },
      logout() {
        this.$store
          .dispatch("LOGOUT")
          .then((res) => {
            localStorage.removeItem("vuex");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err.response.data.errors);
          });
      },
      updateTheme(theme) {
        this.theme = theme;
      },
      open_chat() {
        document.getElementById("chat").classList.remove("hidden");
        document
          .getElementsByTagName("html")[0]
          .classList.add("overflow-y-hidden");
        this.$nextTick(() => {
          this.$refs.getChat.showAllSessions();
        });
      },
    },
    created() {
      this.theme = localStorage.getItem("theme") || "light";
    },
    mounted() {
      feather.replace();
      this.theme = localStorage.getItem("theme") || "light";
      this.$root.$on("openChatForQuotation", (product) => {
        this.open_chat();
        this.$nextTick(() => {
          this.$refs.getChat.askQuotation(product);
        });
      });
    },
    updated() {
      feather.replace();
    },
  };
</script>

<style scoped>
  .logo {
    width: 11rem;
  }

  @media screen and (max-width: 500px) {
    .logo {
      width: 6rem;
    }
  }

  #nav a.router-link-exact-active {
    @apply text-indigo-700;
    @apply font-medium;
  }

  .user-avatar__dropdown {
    position: absolute;
    right: 64px;
    z-index: 70;
  }

  .user-menu--dropdown {
    width: 12rem;
    border-radius: 0.4rem;
    background-color: white;
    -webkit-box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.2);
  }

  .user-menu__link-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  @media (min-width: 1060px) .user-menu__signin-area {
    max-width: inherit;
    margin: 0;
  }

  .user-menu__signin-area {
    padding: 1.2rem;
    text-align: center;
    max-width: 14.8rem;
    margin: 0 auto;
  }

  .user-menu__title-text {
    font-size: .8rem;
    margin-bottom: 0.5rem;
  }

  .user-menu__button {
    height: 4.4rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    white-space: inherit;
  }
  .user-menu-bottom{
    border-top: #e4e3e3 1px solid;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #4067b1;
    width: 51%;
    height: 2rem;
    font-size: 1rem;
    color: white;
    border-radius: 0.3rem;
    /* border-bottom: 0.2rem solid #e65046; */
    font-weight: var(--font-weight-02);
    text-decoration: none;
    line-height: 1;
    cursor: pointer;
    white-space: nowrap;
    margin: auto;
  }
</style>