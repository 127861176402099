<template>
  <div class="flex bg-gray-100 w-full justify-between ads sm:h-60 h-32">
    <a
      href="#"
      class="flex flex-col mr-2 sm:mr-5 w-1/2 items-center border shadow-md md:flex-row"
    >
      <img
        class="object-cover w-full h-full rounded-lg"
        src="https://www.qualitydiamonds.co.uk/media/140077/gold-diamond-jewellery-white-background-cluster.jpeg?anchor=center&mode=crop&width=900&height=500&rnd=132550300240000000"
        alt=""
      />
    </a>
    <a
      href="#"
      class="flex flex-col w-1/2 items-center border shadow-md md:flex-row"
    >
      <img
        class="object-cover w-full h-full rounded-lg"
        src="https://lh3.googleusercontent.com/p/AF1QipMm0c-Nt09Nk-hO-AnHWr_E4QEL4My83z53J9IF=w1080-h608-p-no-v0"
        alt=""
      />
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped></style>
