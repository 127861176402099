<template>
  <div class="relative">
    <button
      type="button"
      class="z-10 relative flex items-center focus:outline-none select-none"
      @click="open = !open"
    >
      <slot name="button"></slot>
    </button>
    <!--dropdown menu-->
    <transition
      enter-active-class="transition-all duration-200 ease-out"
      leave-active-class="transition-all duration-750 ease-in"
      enter-class="opacity-0 scale-75"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-75"
    >
      <div
        class="absolute shadow-lg border rounded py-1 text-sm mt-3 bg-white z-10 right-0"
        :class="width ? width : 'w-64'"
        v-if="open"
      >
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  props: {
    placement: {
      type: String,
      default: "right",
      validator: (value) => ["right", "left"].indexOf(value) !== -1,
    },
    width: {
      type: String,
      default: "w-64",
    },
  },
  methods: {
    closeDrop(e) {
      if (!this.$el.contains(e.target)) {
        this.open = false;
      }
    },
  },
  mounted() {
    const onEscape = (e) => {
      if (e.key === "Esc" || e.key === "Escape") {
        this.open = false;
      }
    };
    document.addEventListener("click", this.closeDrop);

    document.addEventListener("keydown", onEscape);

    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("keydown", onEscape);
    });
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeDrop);
  },
};
</script>
