<template>
  <!-- Header links -->
  <div
    v-if="closeit"
    :class="isOpen ? 'block' : 'hidden'"
    class="m-0 md:flex p-5 sm:p-0 justify-center items-center"
  >
    <a
      role="button"
      @click="closeMenu('/')"
      exact
      class="font-general-medium block text-left txt-mobile sm:text-sm font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 pt-3 sm:pt-2"
      aria-label="Projects"
      >Home</a
    >
    <a
      role="button"
      @click="closeMenu('/councils')"
      class="font-general-medium block text-left txt-mobile sm:text-sm font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="Projects"
      >BCN</a
    >
    <a
      role="button"
      @click="closeMenu('/directory')"
      class="font-general-medium block text-left txt-mobile sm:text-sm font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="Projects"
      >Directory</a
    >
    <a
      role="button"
      @click="closeMenu('/products')"
      class="font-general-medium block text-left txt-mobile sm:text-sm font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="products"
      >Products</a
    >
    <a
      role="button"
      @click="closeMenu('/events')"
      class="font-general-medium block text-left txt-mobile sm:text-sm font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="Projects"
      >Events</a
    >
    <a
      role="button"
      @click="closeMenu('/media')"
      class="font-general-medium block text-left txt-mobile sm:text-sm font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="Projects"
      >Media</a
    >
    <a
      role="button"
      @click="closeMenu('/contact')"
      class="font-general-medium block text-left txt-mobile sm:text-sm font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="Contact"
      >Contact</a
    >

    <a
      v-if="access"
      role="button"
      @click="logout()"
      class="font-general-medium md:hidden block sm:hidden text-left txt-mobile sm:text-sm font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      >Logout</a
    >
    <a
      v-else
      role="button"
      class="font-general-medium md:hidden block text-left txt-mobile sm:text-sm font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300 sm:mx-4 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      @click="closeMenu('/login')"
      >Login</a
    >
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      closeit: true,
    };
  },
  props: ["showModal", "isOpen"],
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    ...mapState({
      access: (state) => state.auth.accesstoken,
    }),
  },
  methods: {
    closeMenu(url) {
      this.$emit("closeit", false);
      this.$router.push(url);
    },
    logout() {
      this.$store
        .dispatch("LOGOUT")
        .then((res) => {
          localStorage.removeItem("vuex");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err.response.data.errors);
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
