import instance from "../axiosConfig";
import auth from "./auth";
export default {
  state: {
    messages: [],
    unread: [],
    notifications: [],
    session_id: null,
    to: null,
  },

  getters: {
    messages: (state) => {
      return state.messages;
    },
    unread: (state) => {
      return state.unread;
    },
    notifications: (state) => {
      return state.notifications;
    },
  },
  mutations: {
    SET_MESSAGE(state, messages) {
      state.messages = messages;
    },
    SET_TO(state, to) {
      state.to = to;
    },
    SET_UNREAD(state, unread) {
      state.unread = unread;
    },
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications;
    },
    APPEND_MESSAGE(state, msg) {
      if (msg.chat_session_id == state.session_id) {
        state.messages = [
          ...state.messages,
          { message: msg.message, user_id: msg.user_id, type:'text' },
        ];
        this.dispatch("make_session_read", { update: false });
      } else {
        this.dispatch("get_all_unread");
      }
    },
    APPEND_QUOTATION(state, msg) {
      this.dispatch("get_all_unread");
      if (msg.chat_session_id == state.session_id) {
        state.messages = [
          ...state.messages,
          { message: msg.message, user_id: msg.user_id, type:'quotation', product:msg.product},
        ];
        this.dispatch("make_session_read",{ update: false });
      }
    },
    SET_SESSION_ID(state, session_id) {
      state.session_id = session_id;
    },
    RESET_SESSION_ID(state) {
      state.session_id = null;
    },
    RESET_MESSAGE(state) {
      state.messages = [];
    }
  },
  actions: {
    get_all_messages({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
      instance
        .get("/chat/get_all_messages/" + state.session_id)
        .then((res) => {
          commit("SET_MESSAGE", res.data.data.messages);
          dispatch("make_session_read",{ update: true });
          resolve(res);
        })
        .catch((err) => {});
      });
    },
    get_all_unread({ commit, state }) {
        instance
          .get("/chat/get_all_unread_messages")
          .then((res) => {
            commit("SET_UNREAD", res.data.messages);
            commit("SET_NOTIFICATIONS", res.data.notifactions);
          })
          .catch((err) => {});
    },
    make_session_read({ dispatch, state }, data) {
      instance
        .get("/chat/make_chat_read/" + state.session_id)
        .then((res) => {
          if (data.update) {
            dispatch("get_all_unread");
          }
        })
        .catch((err) => {});
    },
  },
};
