<template>
  <div class="flex bg-gray-100 w-full justify-between ads sm:h-60 h-32">
    <a
      href="#"
      class="flex flex-col mr-2 sm:mr-5 w-1/2 items-center border shadow-md md:flex-row"
    >
      <img
        class="object-cover w-full h-full rounded-lg"
        src="https://pbs.twimg.com/media/DnYlwcVV4AEppGU.jpg"
        alt=""
      />
    </a>
    <a
      href="#"
      class="flex flex-col w-1/2 items-center border shadow-md md:flex-row"
    >
      <img
        class="object-cover w-full h-full rounded-lg"
        src="http://www.oclicker.com/uploads/92da2d3bd9857_diamond_jpg_999x1366x.jpg"
        alt=""
      />
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped></style>
