<template>
  <hooper
    :wheelControl="false"
    :infiniteScroll="true"
    :settings="settings"
    class="home-grid"
  >
    <slide v-for="company in DCompanies" :key="company.id">
      <a
        class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
        aria-label="Single Project"
      >
        <div class="relative rounded-t-xl">
          <img
            :src="company.logo.desktop_image"
            :alt="company.name"
            class="rounded-t-xl border-none"
          />
        </div>
        <div class="text-center px-3 py-3 leading-none">
          <p
            class="overflow-hidden whitespace-nowrap txt-mobile leading-normal text-sm sm:text-md mb-0 sm:mb-2"
          >
            {{ company.name }}
          </p>
        </div>
      </a>
    </slide>
    <navigation slot="hooper-addons"></navigation>
  </hooper>
</template>
<script>
import projects from "../../data/projects";
import { Hooper, Slide, Navigation } from "hooper";
import "hooper/dist/hooper.css";
export default {
  props: ["singleProjectHeader", "DCompanies"],
  data() {
    return {
      projects,
      selectedCategory: "",
      searchProject: "",
      settings: {
        itemsToShow: 2.5,
        breakpoints: {
          600: {
            itemsToShow: 3,
          },
          1000: {
            itemsToShow: 6,
            pagination: "fraction",
          },
        },
      },
    };
  },
  components: {
    Slide,
    Hooper,
    Navigation,
  },
};
</script>
<style scoped>
.hooper {
  height: unset;
  margin-bottom: 5px;
}
.hooper-slide,
.hooper >>> .hooper-slide.is-clone.is-active {
  margin: 0 15px;
  border: 1px solid #eee;
  border-radius: 0.75rem;
}
.hooper >>> .hooper-slide:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
@media screen and (max-width: 500px) {
  .hooper-slide,
  .hooper >>> .hooper-slide.is-clone.is-active {
    margin: 0 2px;
  }
  .hooper img {
    height: auto;
  }
}
@media screen and (max-width: 300px) {
  .txt-mobile {
    font-size: 9px;
  }
  .txt-mobile2 {
    font-size: 8px;
  }
}
</style>
