<template>
  <section class="container home mx-auto sm:max-w-none" v-if="homepage">
    <div class="">
      <div class="flex justify-between items-center mb-1">
        <div class="text-left mb-5">
          <p
            class="headtext text-sm sm:text-base text-ternary-dark dark:text-ternary-light"
          >
            Business Councils
          </p>
        </div>
      </div>
      <BCSlider
        class="home-grid"
        v-if="homepage"
        :councils="homepage.councils"
      />
      <div class="more_btn mb-5 mt-2 mr-3">
        <div class="flex justify-end">
          <router-link
            to="/councils"
            class="flex items-center"
            aria-label="see all"
          >
            <Button
              title="More ..."
              class="font-bold text-indigo-700 txt-mobile text-sm sm:text-md"
            />
          </router-link>
        </div>
      </div>
    </div>
    <smallBanner class="mb-5" />
    <div class="">
      <div class="flex justify-between items-center mb-1">
        <div class="text-left mb-5">
          <p
            class="headtext text-sm sm:text-base text-ternary-dark dark:text-ternary-light"
          >
            Profiles
          </p>
        </div>
      </div>
      <CompanySlider
        class="home-grid"
        v-if="homepage"
        :companies="homepage.companies"
      />
      <div class="more_btn mr-3 mb-5 mt-2">
        <div class="flex justify-end">
          <router-link
            to="/profiles"
            class="flex items-center"
            aria-label="see all"
          >
            <Button
              title="More ..."
              class="font-bold text-indigo-700 txt-mobile text-sm sm:text-md"
            />
          </router-link>
        </div>
      </div>
    </div>
    <div class="">
      <div class="flex justify-between items-center mb-1">
        <div class="text-left mb-5">
          <p
            class="headtext text-sm sm:text-base text-ternary-dark dark:text-ternary-light"
          >
            Products
          </p>
        </div>
      </div>
      <ProductSlider v-if="homepage" :products="homepage.products" />
      <Quatation />
      <div class="more_btn mr-3 mb-5 mt-2">
        <div class="flex justify-end">
          <router-link to="/" class="flex items-center" aria-label="see all">
            <Button
              title="More ..."
              class="font-bold text-indigo-700 txt-mobile text-sm sm:text-md"
            />
          </router-link>
        </div>
      </div>
    </div>
    <smallBanner2 class="mb-5" />
    <div class="">
      <div class="flex justify-between items-center mb-1">
        <div class="text-left mb-5">
          <p
            class="headtext text-sm sm:text-base text-ternary-dark dark:text-ternary-light"
          >
            Directory
          </p>
        </div>
      </div>
      <DirectorySlider
        class="home-grid"
        v-if="homepage"
        :DCompanies="homepage.directory"
      />
      <div class="more_btn mb-5 mt-2 mr-3">
        <div class="flex justify-end">
          <router-link
            to="/directory"
            class="flex items-center"
            aria-label="see all"
          >
            <Button
              title="More ..."
              class="font-bold text-indigo-700 txt-mobile text-sm sm:text-md"
            />
          </router-link>
        </div>
      </div>
    </div>
    <smallBanner3 class="mb-5" />
    <div class="">
      <div class="flex justify-between items-center mb-1">
        <div class="text-left mb-5">
          <p
            class="headtext text-sm sm:text-base text-ternary-dark dark:text-ternary-light"
          >
            Events
          </p>
        </div>
      </div>
      <EventsSlider
        class="home-grid"
        v-if="homepage"
        :events="homepage.events"
        @event="getEvent($event)"
        @selected="getEventData($event)"
      />
      <div class="more_btn mb-5 mt-2 mr-3">
        <div class="flex justify-end">
          <router-link
            to="/events"
            class="flex items-center"
            aria-label="see all"
          >
            <Button
              title="More ..."
              class="font-bold text-indigo-700 txt-mobile text-sm sm:text-md"
            />
          </router-link>
        </div>
      </div>
    </div>
    <div class="">
      <div class="flex justify-between items-center mb-1">
        <div class="text-left mb-5">
          <p
            class="headtext text-sm sm:text-base text-ternary-dark dark:text-ternary-light"
          >
            Media
          </p>
        </div>
      </div>
      <MediaSlider
        class="home-grid"
        v-if="homepage"
        :media="homepage.medias"
        @media="getMedia($event)"
        @selected="getMediaData($event)"
      />
      <div class="more_btn mb-5 mt-2 mr-3">
        <div class="flex justify-end">
          <router-link
            to="/media"
            class="flex items-center"
            aria-label="see all"
          >
            <Button
              title="More ..."
              class="font-bold text-indigo-700 txt-mobile text-sm sm:text-md"
            />
          </router-link>
        </div>
      </div>
    </div>
    <eventDialog
      v-if="showEvent"
      @close="getEvent($event)"
      :selectedEvent="selectedEvent"
    />
    <mediaDialog
      v-if="showMedia"
      @close="getMedia($event)"
      :selectedMedia="selectedMedia"
    />
  </section>
  <div class="flex justify-center items-center mt-64" v-else>
    <Spinner name="line-scale" color="#82a3e0" />
  </div>
</template>
<script>
import feather from "feather-icons";
import CompanySlider from "./CompanySlider";
import BCSlider from "./BCSlider";
import DirectorySlider from "./DirectorySlider";
import EventsSlider from "./EventsSlider";
import ProductSlider from "./productSlider";
import MediaSlider from "./MediaSlider";
import projects from "../../data/projects";
import Button from "../reusable/Button.vue";
import smallBanner from "./smallBanner.vue";
import smallBanner2 from "./smallBanner2.vue";
import smallBanner3 from "./smallBanner3.vue";
import mediaDialog from "./mediaDialog.vue";
import eventDialog from "./eventDialog.vue";
import instance from "@/store/axiosConfig";
export default {
  components: {
    Quatation: () => import("../shared/ChatModal"),
    Button,
    CompanySlider,
    BCSlider,
    DirectorySlider,
    EventsSlider,
    ProductSlider,
    MediaSlider,
    mediaDialog,
    eventDialog,
    smallBanner,
    smallBanner2,
    smallBanner3,
  },
  props: ["singleProjectHeader"],
  data: () => {
    return {
      projects,
      selectedCategory: "",
      searchProject: "",
      showEvent: false,
      showMedia: false,
      selectedEvent: null,
      selectedMedia: null,
      homepage: null,
    };
  },
  methods: {
    getEventData(value) {
      this.selectedEvent = value;
    },
    getMediaData(value) {
      this.selectedMedia = value;
    },
    getEvent(value) {
      this.showEvent = value;
    },
    getMedia(value) {
      this.showMedia = value;
    },
    getHomepage() {
      instance
        .get("/public/homepage")
        .then((res) => {
          console.log(res.data.data);
          this.homepage = res.data;
          this.$emit("home", this.homepage);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
  mounted() {
    feather.replace();
    this.getHomepage();
  },
};
</script>

<style scoped>
.headtext {
  padding-bottom: 4px;
  border-bottom: 1px solid rgb(193 32 51);
  color: #1049b6;
  text-transform: capitalize;
}
.home {
  padding-left: 1rem;
  padding-right: 1rem;
}
.home-grid >>> .hooper-track {
  margin-bottom: 5px;
}
/* .home-grid >>> .hooper-slide {
  height: 250px;
} */
.plus-search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: 1px solid #ccc;
  border-radius: 50%;
  font-size: 24px;
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .home-grid >>> .hooper-slide {
    height: auto;
  }
}
@media screen and (max-width: 300px) {
  .plus-search {
    width: 20px;
    height: 20px;
    font-size: 18px;
  }
  .txt-mobile {
    font-size: 9px;
  }
  .txt-mobile2 {
    font-size: 8px;
  }
}
</style>
