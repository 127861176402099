<template>
  <hooper :wheelControl="false" :infiniteScroll="true" :autoPlay="true" :playSpeed="10000" class="main-banner">
    <slide class="relative">
      <img src="../../assets/img/rakbn.jpeg" alt="" />
      <div class="text-center leading-8 company-txt">
        
      </div>
    </slide>
    <slide class="relative">
      <img src="../../assets/img/ikeabannerr.jpeg" alt="" />
      <div class="text-center leading-8 company-txt">
        
      </div>
    </slide>
    <hooper-pagination slot="hooper-addons"></hooper-pagination>
  </hooper>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";
export default {
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
};
</script>
<style>
.main-banner {
  height: 367px;
}
.hooper-pagination {
  display: none;
}
.main-banner img {
  width: 100%;
  min-width: 100%;
}
.company-txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hooper-list{
  border-radius: 14px;
}
@media screen and (max-width: 900px) {
  .hooper,.main-banner img{
    height: 320px;
  }
}
@media screen and (max-width: 500px) {
  .hooper,.main-banner img{
    height: 152px;
  }
  .hooper-list{
  border-radius: unset;
  }
}
</style>
