import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import chat from "./modules/chat";

Vue.use(Vuex);

const dataState = createPersistedState({
  paths: ['auth','chat.unread']
});
export default new Vuex.Store({
  namespaced: true,
  modules: {
    auth,
    chat,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  plugins: [dataState]
});
