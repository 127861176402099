import instance from "../axiosConfig";
export default {
  state: {
    accesstoken: null,
    council_id: null,
    company_id: null,
    loggedIn: false,
    user_type: null,
    user_id: null,
    name: null,
    user_img: null,
  },

  getters: {
    auth(state) {
      return state.loggedIn;
    },
    userid(state) {
      return state.user_id;
    },
  },

  mutations: {
    setAccessToken(state, token) {
      state.accesstoken = token;
    },
    setUserType(state, user_type) {
      state.user_type = user_type;
    },
    setUserId(state, user_id) {
      state.user_id = user_id;
    },
    setCompanyId(state, company_id) {
      state.company_id = company_id;
    },
    setCouncilID(state, council_id) {
      state.council_id = council_id;
    },
    setLoggedIn(state) {
      state.loggedIn = true;
    },
    setUserName(state, username) {
      state.name = username;
    },
    setUserImg(state, user_img) {
      state.user_img = user_img;
    },
  },

  actions: {
    LOGIN({ commit, dispatch }, userData) {
      return new Promise((resolve, reject) => {
        instance
          .post("/login", {
            ...userData,
          })
          .then((response) => {
            if (response.data.status == "Success") {
              commit("setLoggedIn");
              commit("setAccessToken", response.data.token);
              commit("setUserId", response.data.user_id);
              commit("setUserName", response.data.name);
              commit("setUserType", response.data.user_type);
              commit("setCouncilID", response.data.council_id);
              commit("setCompanyId", response.data.company_id);
              commit("setUserImg", response.data.profile.thumbnail_image);
              dispatch("START_LISTENING_PUSHER_CHANNEL");
              dispatch("get_all_unread");
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    LOGOUT({ commit }) {
      return new Promise((resolve, reject) => {
        instance
          .get("/logout")
          .then((response) => {
            console.log(response);
            //reset store
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    START_LISTENING_PUSHER_CHANNEL({ commit, state }) {
      window.Echo.private(`chat.` + state.user_id).listen(
        ".message.sent",
        (e) => {
          commit("APPEND_MESSAGE", e.chatMessage);
        }
      );
      window.Echo.private(`chat.` + state.user_id).listen(
        ".quotation.sent",
        (e) => {
          let msg = e.chatMessage;
          msg.product = e.product;
          commit("APPEND_QUOTATION", e.chatMessage);
        }
      );
    },
  },
};
