<template>
  <div
    class="rounded-lg max-w-md w-full sm:w-3/5 md:w-1/2 lg:w-1/3 fixed right-0 z-50 h-full overflow-y-hidden hidden top-0 md:top-auto h-full md:h-5/6 shadow-lg bg-white"
    style="border: 1px solid #eee"
  >
    <!-- header -->
    <div
      class="p-4 rounded-lg dark:bg-gray-700 top-3 right-3 w-full overflow-hidden"
      style="height: 75px"
    >
      <div
        class="flex justify-between items-center py-2 pb-4 rounded-t border-b dark:border-gray-600"
      >
        <div class="flex justify-between items-center">
          <svg
            v-if="to"
            @click="goBack"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
          <h3
            class="text-sm sm:text-base font-semibold text-gray-900 dark:text-white"
          >
            {{ to ? to : "Messages" }}
          </h3>
        </div>
        <button
          @click="closeChat()"
          type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          data-modal-toggle="defaultModal"
        >
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
    <!-- Modal body -->
    <div
      v-if="!to"
      class="px-4 absolute w-full overflow-auto"
      style="height: 100%"
    >
      <input
        @input="get_contacts()"
        v-model="search"
        type="text"
        placeholder="Search for companies, councils..."
        class="text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-1 border-gray-300"
      />
      <div class="relative" v-if="search_contacts">
        <div
          class="p-4 border border-gray-300 rounded-lg absolute top-2 bg-white w-full z-50"
        >
          <div
            v-for="(contact, index) in search_contacts"
            :key="index"
            role="button"
            @click="getSesseionid(contact.user.id, contact.name)"
            class="my-2 text-gray-900 bg-white w-full islam rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <div
              rol="button"
              class="relative flex items-center w-full py-2 pl-1 text-base font-medium rounded-t-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white"
            >
              <img
                class="w-8 h-8 mr-2 fill-curren p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500t"
                src="../../assets/logo.png"
                alt="currentColor"
              />
              <div class="flex-row text-left items-center">
                <h4>{{ contact.name }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex justify-center items-center mt-64"
        v-if="sessions == null"
      >
        <p>Loading...</p>
      </div>
      <div
        class="flex justify-between items-center flex-col h-full relative"
        v-else-if="sessions && sessions.length == 0"
      >
        <div class="flex justify-center flex-col items-center my-auto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-28 w-28 text-gray-300"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <p class="text-gray-300">Sorry! There are no messages for you.</p>
        </div>
      </div>
      <div v-else>
        <p class="text-gray-400 text-xs my-3">Chats</p>
        <div
          v-for="(session, index) in sessions"
          :key="index"
          role="button"
          @click="
            getSesseionMessage(
              session.session_id,
              session.participants[0].user.name
            )
          "
          class="my-2 text-gray-900 bg-white rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        >
          <div
            rol="button"
            class="relative flex items-center w-full px-4 py-2 text-base font-medium rounded-t-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white"
          >
            <img
              class="w-8 h-8 mr-2 fill-curren p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500t"
              :src="session.participants[0].user.details.logo.desktop_image"
              alt="currentColor"
            />
            <div class="flex-row text-left items-center">
              <h4>{{ session.participants[0].user.name }}</h4>
              <p class="text-sm text-gray-500">
                {{
                  session.latest_message
                    ? session.latest_message.message
                    : "No Message"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      ref="mContainer"
      class="p-4 absolute w-full overflow-auto"
      style="height: 100%"
    >
      <div class="messages">
        <ul class="" v-if="messages">
          <li
            class="pl-2 pt-1 ml-2 message-text mb-2"
            v-for="message in messages"
            :key="message.id"
          >
            <div
              class="flex"
              v-if="message.type == 'text'"
              :class="{ 'justify-end': message.user_id == userId }"
            >

              <span v-if="message.user_id == userId">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </span>
              <span
                class="drop-shadow-md text-sm font-medium mr-2 px-2.5 py-0.5 rounded"
                :class="{
                  'bg-gray-100 text-gray-800': message.user_id != userId,
                  'bg-blue-100 text-blue-800': message.user_id == userId,
                }"
                >{{ message.message }}
              </span>
            </div>
            <div
              v-else
              class="flex m-2"
              :class="{ 'justify-end': message.user_id == userId }"
            >
              <div class="w-1/2 -mx-4">
                <div class="w-full">
                  <a
                    href=""
                    class="c-card block bg-white shadow-md hover:shadow-xl rounded-lg overflow-hidden"
                  >
                    <div class="relative pb-48 overflow-hidden">
                      <img
                        class="absolute inset-0 w-full object-cover"
                        :src="message.product.image.desktop_image"
                        alt=""
                      />
                    </div>
                    <div class="p-4">
                      <h2 class="mt-2 mb-2 font-bold">
                        {{ message.product.name }}
                      </h2>
                      <p class="text-sm">{{ message.product.description }}</p>
                    </div>
                    <div
                      class="p-2 border-t border-b text-xs text-white bg-blue-600"
                    >
                      <span class="flex items-center">
                        <span
                          class="inline-block px-2 py-1 leading-none rounded-full font-semibold tracking-wide text-xs m-auto"
                          >Requesting for a quotation.</span
                        >
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Modal footer -->
    <div
      class="absolute -bottom-1 md:bottom-0 w-full overflow-hidden flex items-center justify-between"
      style="height: 70px"
    >
      <form
        v-if="to"
        class="flex items-center justify-between bg-gray-100 rounded-b border-t border-gray-200 dark:border-gray-600 p-3 md:pb-2 lg:pb-2 absolute bottom-0 w-full"
      >
        <input
          type="text"
          v-model="message"
          class="text-gray-900 text-sm rounded-lg block w-full p-2.5 border-0"
        />
        <div class="mx-2" role="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
            />
          </svg>
        </div>
        <div
          class="mx-2"
          role="button"
          @click="
            $store.state.chat.session_id == null
              ? createSession(reciever_id, message)
              : sendMessage(reciever_id, message)
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 18 18"
            aria-labelledby="Send"
            role="role"
            color="#565867"
          >
            <title id="Send" lang="en">Send</title>
            <g fill="currentColor">
              <path
                d="M17.218,2.268L2.477,8.388C2.13,8.535,2.164,9.05,2.542,9.134L9.33,10.67l1.535,6.787c0.083,0.377,0.602,0.415,0.745,0.065l6.123-14.74C17.866,2.46,17.539,2.134,17.218,2.268 M3.92,8.641l11.772-4.89L9.535,9.909L3.92,8.641z M11.358,16.078l-1.268-5.613l6.157-6.157L11.358,16.078z"
              ></path>
            </g>
          </svg>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import instance from "@/store/axiosConfig";
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      sessions: null,
      userName: "",
      to: null,
      reciever_id: null,
      message: null,
      search_contacts: null,
      search: null,
    };
  },
  computed: {
    ...mapState({
      access: (state) => state.auth.accesstoken,
      name: (state) => state.auth.name,
      userImg: (state) => state.auth.user_img,
      userId: (state) => state.auth.user_id,
    }),
    ...mapGetters(["messages"]),
  },
  methods: {
    goBack() {
      if(this.sessions==null)
      {
        this.showAllSessions();
      }
      this.to = null;
      this.$store.commit("RESET_SESSION_ID");
      this.$store.commit("RESET_MESSAGE");
    },
    get_contacts() {
      if (this.search == "") {
        this.search_contacts = null;
      }
      instance
        .get("/chat/search_all_contacts/" + this.search)
        .then((res) => {
          console.log(res.data);
          this.search_contacts = [];
          res.data.forEach((contact) => {
            this.search_contacts.push(contact);
          });
          console.log(this.search_contacts);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    getSesseionid(reciever_id, to) {
      this.reciever_id = reciever_id;
      instance
        .post("/chat/get_session", {
          reciever_id,
        })
        .then((res) => {
          if (res.data.session_id) {
            this.getSesseionMessage(res.data.session_id, to);
          } else {
            this.to = to;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    askQuotation(product) {
      this.to = product.company.name;
      instance
        .post("/chat/get_session", {
          reciever_id: product.company.user.id,
        })
        .then((res) => {
          if (res.data.session_id) {
            this.$store.commit("SET_SESSION_ID", res.data.session_id);
            this.$store
              .dispatch("get_all_messages")
              .then((res) => {
                this.sendQuotation(product);
              })
              .catch((err) => {
                console.log(err.response.data.errors);
              });
          } else {
            this.createSessionAndSend(product);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeChat() {
      this.to = null;
      this.$emit("close_chat", false);
      this.$store.commit("RESET_SESSION_ID");
      this.$store.commit("RESET_MESSAGE");
    },
    updateUsername() {
      this.name = this.userName;
      console.log(this.userName);
      this.userName = "";
    },
    createSession(reciever_id, message) {
      instance
        .post("/chat/create_session", {
          reciever_id,
        })
        .then((res) => {
          this.$store.commit("SET_SESSION_ID", res.data.session_id);
          this.sendMessage(reciever_id, message);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createSessionAndSend(product) {
      instance
        .post("/chat/create_session", {
          reciever_id: product.company.user.id,
        })
        .then((res) => {
          this.$store.commit("SET_SESSION_ID", res.data.session_id);
          this.sendQuotation(product);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendMessage(reciever_id, message) {
      this.messages.push({
        message: message,
        user_id: this.userId,
        type: "text",
      });
      this.message = "";
      this.$nextTick(() => {
        this.$refs.mContainer.scrollTop = this.$refs.mContainer.scrollHeight;
      });
      instance
        .post("/chat/send", {
          session_id: this.$store.state.chat.session_id,
          reciever_id,
          message: message,
          type: "text",
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendQuotation(product) {
      this.messages.push({
        message: "Asking for quotation",
        user_id: this.userId,
        type: "quotation",
        product,
      });
      instance
        .post("/chat/send", {
          session_id: this.$store.state.chat.session_id,
          reciever_id: product.company.user.id,
          message: "Asking for quotation",
          type: "quotation",
          frgn_key: product.product_id,
        })
        .then((res) => {
          //this.getSesseionMessage(this.$store.state.chat.session_id, to);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showAllSessions() {
      if (this.$store.state.auth.loggedIn) {
        instance
          .get("/chat/get_all_sessions")
          .then((res) => {
            this.sessions = res.data.data.sessions;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getSesseionMessage(session_id, to) {
      this.to = to;
      this.$store.commit("SET_SESSION_ID", session_id);
      this.$store.dispatch("get_all_messages");
    },
  },
  watch: {
    messages: function () {
      this.$nextTick(() => {
        if (this.$refs.mContainer != undefined) {
          this.$refs.mContainer.scrollTop = this.$refs.mContainer.scrollHeight;
        }
      });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic|Material+Icons");

#app {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}
.messages li:last-child {
  margin-bottom: 160px;
}
.login {
  background: #fff;
  width: 40%;
  height: 50vh;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}

h3 {
  font-size: 30px;
  text-align: center;
}

input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgb(156, 156, 156);
  padding-left: 2px;
  padding-right: 2px;
}

.message-body {
  width: 40%;
  height: 80vh;
  margin: auto;
}

.message-text {
  min-width: 10%;
  border-radius: 4px;
}

.message {
  font-size: 14px;
}

.mg-text {
  color: rgb(0, 195, 255);
  font-weight: bolder;
}

.message-body input {
  width: 80%;
  border-radius: 4px;
  border: 1px solid rgb(156, 156, 156);
  height: 6vh;
  padding-left: 2px;
  padding-right: 2px;
}

.card {
  width: 100%;
  height: 75vh;
  margin: auto;
}

.card-body {
  min-height: 50vh;
  overflow-x: scroll;
}

.chat {
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: #fff;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 10px;
  border-radius: 20px;
}

.out p.chat-text {
  background-color: #423ad3;
  border-radius: 10px;
  padding: 5px 10px;
  color: #eee;
}
</style>
