import axios from "axios";
import Vue from "vue";
import router from "../router";
import store from "./index";
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});
console.log("config", store.state.auth.accesstoken);
instance.interceptors.request;
instance.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization && store.state.auth.accesstoken != null) {
      config.headers.Authorization = `Bearer ${store.state.auth.accesstoken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { response } = error;
    console.log(response);
    if (
      response &&
      response.status === 401 &&
      response.data.message == "Unauthenticated."
    ) {
      localStorage.removeItem("vuex");
      //reset store
      Vue.swal({
        icon: "error",
        title: "Oops...",
        text: "Your session has been expired",
      });
      window.location.reload();
    }
    if (response && response.status === 500) {
      //reset store
      Vue.swal  .fire({
        icon: 'error',
        title: 'Something went wrong!',
        text: 'Please try again',
        showConfirmButton:false,
        timer: 1500 
      })
    }
    return Promise.reject(error);
  }
);
export default instance;
