<template>
  <hooper
    :infiniteScroll="true"
    :wheelControl="false"
    :settings="settings"
    class="council-grid"
  >
    <slide v-for="company in councils" :key="company.id">
      <router-link
        :to="{
          path: '/council',
          query: { name: company.council_name, id: company.id },
        }"
        class="rounded-xl hover:shadow-xl cursor-pointer ml-2 mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
        aria-label="Single Project"
      >
        <div class="relative rounded-t-xl h-full">
          <img
            :src="company.cover.desktop_image"
            :alt="company.name"
            class="rounded-t-xl border-none cover-image"
          />
          <div class="circle-border">
            <div class="overlapped-logo">
              <img
                :src="company.logo.desktop_image"
                :alt="company.name"
                class="rounded-t-xl border-none"
              />
            </div>
          </div>
        </div>
        <div class="text-center px-2 pt-3 leading-none">
          <p
            class="txt-mobile overflow-hidden whitespace-nowrap leading-normal text-sm sm:text-md mb-0"
          >
            {{ company.council_name }}
          </p>
        </div>
      </router-link>
    </slide>
    <navigation slot="hooper-addons"></navigation>
  </hooper>
</template>
<script>
import projects from "../../data/projects";
import { Hooper, Slide, Navigation } from "hooper";
import "hooper/dist/hooper.css";
export default {
  props: ["singleProjectHeader", "councils"],
  data() {
    return {
      projects,
      selectedCategory: "",
      searchProject: "",
      settings: {
        itemsToShow: 2.5,
        breakpoints: {
          600: {
            itemsToShow: 3,
          },
          1000: {
            itemsToShow: 8,
            pagination: "fraction",
          },
        },
      },
    };
  },
  components: {
    Slide,
    Hooper,
    Navigation,
  },
};
</script>
<style scoped>
.hooper-list li.is-current {
  margin-left: 0;
}
.hooper {
  height: unset;
  margin-bottom: 5px;
}
.hooper-slide,
.hooper >>> .hooper-slide.is-clone.is-active {
  margin: 0 15px;
  border: 1px solid #eee;
  border-radius: 0.75rem;
}
.hooper >>> .hooper-slide:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
@media screen and (max-width: 500px) {
  .hooper-slide,
  .hooper >>> .hooper-slide.is-clone.is-active {
    margin: 0 2px;
  }
}
@media screen and (max-width: 300px) {
  .txt-mobile {
    font-size: 9px;
  }
  .txt-mobile2 {
    font-size: 8px;
  }
}
.hooper-list a {
  margin: 0;
}
.cover-image {
  height: 57px;
}
.circle-border {
  border-radius: 50%;
  width: 110px;
  height: 110px;
  margin: auto !important;
  padding-top: 35px;
  position: relative;
  top: -65px;
}
.overlapped-logo {
  background: white;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: auto !important;
  padding-top: 20px;
  position: relative;
  top: -18px;
}
.overlapped-logo img {
  width: 74%;
  margin: auto;
}
.leading-none {
  position: relative;
  top: -49px;
}
.council-grid >>> .hooper-slide {
  height: 170px !important;
}
@media screen and (max-width: 478px) {
  .council-grid >>> .hooper-slide {
    height: 128px !important;
  }
}
.txt-mobile {
  font-size: 11px !important;
}
</style>
