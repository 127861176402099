<template>
  <div>
    <hooper :infiniteScroll="true" :wheelControl="false" :settings="settings">
      <slide v-for="(product, index) in products" :key="index" class="mb-3">
        <a class="group" @click="showChat = true">
          <div
            class="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8"
          >
            <img
              :src="product.image.desktop_image"
              alt="Tall slender porcelain bottle with natural clay textured body and cork stopper."
              class="w-full object-center object-cover group-hover:opacity-75"
            />
          </div>
          <div class="p-3 text-center">
            <h3
              class="mt-4 text-sm text-gray-700 overflow-hidden whitespace-nowrap"
            >
              {{ product.name }}
            </h3>
            <p class="mt-1 text-xs text-gray-900">
              {{ product.description }}
            </p>
            <p class="mt-1 text-xs text-gray-400">Posted by :</p>
            <p class="mt-1 text-xs text-gray-400">
              {{ product.company.name }}
            </p>
            <button
              type="button"
              class="text-yellow-600 hover:text-white border border-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-2 py-1 text-center mt-2 dark:border-yellow-500 dark:text-yellow-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-yellow-800"
              v-if="$store.state.auth.user_id == product.company.user.id"
            >
              Your own product
            </button>
            <button
              @click="askfor(product)"
              type="button"
              class="text-blue-600 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-2 py-1 text-center mt-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-800"
              v-else
            >
              Ask For Quotation
            </button>
          </div>
        </a>
      </slide>
      <navigation slot="hooper-addons"></navigation>
    </hooper>
    <Chat v-if="showChat" />
  </div>
</template>
<script>
import instance from "@/store/axiosConfig";
import projects from "../../data/projects";
import { Hooper, Slide, Navigation } from "hooper";
import "hooper/dist/hooper.css";
import Chat from "../shared/ChatModal.vue";
export default {
  props: ["singleProjectHeader", "products"],
  data() {
    return {
      projects,
      showChat: false,
      selectedCategory: "",
      searchProject: "",
      settings: {
        itemsToShow: 2,
        breakpoints: {
          600: {
            itemsToShow: 3,
          },
          1000: {
            itemsToShow: 8,
            pagination: "fraction",
          },
        },
      },
    };
  },
  components: {
    Slide,
    Hooper,
    Navigation,
    Chat,
  },
  methods: {
    askfor(product) {
      this.$root.$emit("openChatForQuotation",product);
    },
  },
  created() {
    console.log("state", this.$store.state);
  },
};
</script>
<style scoped>
.hooper {
  height: unset;
  margin-bottom: 5px;
}
.group img {
  height: 200px;
}
.group h3,
p {
  white-space: nowrap;
  overflow: hidden;
}
.hooper-slide,
.hooper >>> .hooper-slide.is-clone.is-active {
  margin: 0 15px;
  border: 1px solid #eee;
  /* box-shadow: 0 0 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05); */
  /* border-bottom: 2px solid #eee; */
  border-radius: 0.75rem;
}
.hooper >>> .hooper-slide:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
@media screen and (max-width: 500px) {
  .hooper-slide,
  .hooper >>> .hooper-slide.is-clone.is-active {
    margin: 0 2px;
  }
  .hooper img {
    height: 170px;
  }
}
@media screen and (max-width: 300px) {
  .txt-mobile {
    font-size: 9px;
  }
  .txt-mobile2 {
    font-size: 8px;
  }
}
</style>
