<template>
  <div>
    <div
      class="flex h-screen items-center justify-center w-full fixed top-0 right-0 bottom-0 bg-gray-900/50"
    >
      <div
        class="max-w-sm overflow-hidden rounded-xl bg-white shadow-md duration-200 hover:shadow-xl relative"
      >
        <img
          :src="selectedEvent.image.desktop_image"
          alt="plant"
          class="h-auto w-full"
        />
        <button
          @click="closeDialog()"
          type="button"
          class="absolute top-3 right-3 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          data-modal-toggle="defaultModal"
        >
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <div class="p-5">
          <ul class="list-group my-3">
            <li class="pt-0 border-0 list-group-item ps-0 text-dark mb-2">
              <h4 class="text-dark text-sm">
                <strong>{{ selectedEvent.name }}</strong>
              </h4>
            </li>
            <li class="text-xs border-0 list-group-item ps-0 text-dark mb-2">
              <strong class="">Location:</strong> &nbsp;
              {{ selectedEvent.location }}
            </li>
            <li class="text-xs border-0 list-group-item ps-0 text-dark mb-2">
              <strong class="text-dark">Date:</strong> &nbsp;
              {{ selectedEvent.event_date }}
            </li>
            <li class="text-xs border-0 list-group-item ps-0 text-dark mb-2">
              <strong class="text-dark">Link:</strong> &nbsp;
              {{ selectedEvent.link }}
            </li>
          </ul>
          <button
            class="w-full rounded-md bg-indigo-600 py-2 text-indigo-100 hover:bg-indigo-500 hover:shadow-md duration-75"
          >
            Visit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["selectedEvent"],
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="stylus" scoped></style>
